<template>
<div  class="light-tag"
      :class="[color, size]">
  <i  v-if="icon"
      class="tag-icon vertical-align-middle"
      :class="[`text-${color}`, icon]" />
  <div  v-else
        class="small-square"
        :class="color" />
  {{ label }}
</div>
</template>

<script>
export default {
  name: 'color-tag',
  props: {
    color: {
      type: String,
      required: true
    },
    size: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      default: null
    }
  },
}
</script>
<style lang="scss">
i.tag-icon {
  font-size: .8em;
  width: .85em;
  text-align: center;
}

.light-tag.lg {
  .small-square {
    margin-right: .2em;
  }
}
</style>