<template>
<div class="form-group unselectable">
  <div class="tick-view">
    <i  v-if="value"
        class="fas fa-check icon-md text-center text-primary" />
    <i  v-else
        class="fas fa-times icon-md text-center text-danger" />
    {{ label }}
  </div>
</div>
</template>

<script>
export default {
  name: 'file-view',
  props: {
    value: {
      type: Boolean,
      required: true
    },
    label: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.tick-view {
  font-size: $input-btn-font-size;
  font-weight: bold;
  background: $accent-light;
  padding: $input-btn-padding;
  border-radius: $border-radius;
}
</style>