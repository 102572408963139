<template>
<div>

	<div class="iframe-wrapper" :class="{ 'hide': !show_form }">
		<iframe :id="iframe_key" :src="iframe_src" />
	</div>

	<div  :id="print_element_id"
				class="d-none">

		<div class="-header -space-below -w100">
			<img src="https://cuvdl-public.s3.ap-southeast-1.amazonaws.com/misc/cuvdl-logo.png" class="-logo">
			<div class="-details">
				<div class="-font-xl">{{ `ใบส่งตัวอย่าง${submission.submission_type}` }}</div>
				<div class="-font-md">หน่วยชันสูตรโรคสัตว์กลาง คณะสัตวแพทยศาสตร์ จุฬาลงกรณ์มหาวิทยาลัย</div>
				<div class="-font-md">ถนนอังรีดูนังต์ แขวงวังใหม่ เขตพญาไท กทม. 10330</div>
				<div class="-font-md">โทร. 02-218-9606-6 email: cuvdl.thailand@gmail.com</div>
			</div>
		</div>

		<table class="-space-below">
			<tr>
				<td colspan="1" class="-w50">
					<div class="-cell">
						<label class="-w25">ผู้ส่งตัวอย่าง</label>
						<p>{{ submission.submitter.name }}</p>
					</div>
				</td>
				<td colspan="2">
					<div class="-cell">
						<label class="-center -w100">สำหรับเจ้าหน้าที่</label>
					</div>
				</td>
			</tr>
			<tr>
				<td rowspan="3" colspan="1">
					<div class="-cell">
						<label class="-w25">ที่อยู่</label>
						<p class="-pre">{{ submission.submitter.address }}</p>
					</div>
				</td>
				<td class="-w25" colspan="1">
					<div class="-cell">
						<label class="-w25">วันที่ส่ง</label>
						<p class="-ink-red">{{ to_display_date(new Date()) }}</p>
					</div>
				</td>
				<td class="-w25" colspan="1">
					<div class="-cell">
						<label class="-w25">วันที่รับ</label>
						<p class="-ink-red">{{ submission.sample_received_date? to_display_date(submission.sample_received_date) : '' }}</p>
					</div>
				</td>
			</tr>
			<tr>
				<td colspan="2">
					<div class="-cell">
						<label class="-w25">เลขที่ส่งตัวอย่าง</label>
						<p class="-ink-red">{{ submission.BestLIMS_key || submission.backend_key }}</p>
					</div>
				</td>
			</tr>
			<tr>
				<td colspan="2">ผู้รับ</td>
			</tr>
			<tr>
				<td colspan="1">
					<div class="-cell">
						<label class="-w25">อีเมล</label>
						<p>{{ submission.submitter.email }}</p>
					</div>
				</td>
				<td rowspan="2" colspan="2"></td>
			</tr>
			<tr>
				<td colspan="1">
					<div class="-cell">
						<label class="-w25">โทรศัพท์</label>
						<p>{{ submission.submitter.phone }}</p>
					</div>
				</td>
			</tr>
		</table>

		<table>
			<tr>
				<td class="-w50" rowspan="1" colspan="1">
					<div class="-cell">
						<label class="-w25">เจ้าของตัวอย่าง</label>
						<p>{{ submission.sample_owner.name }}</p>
					</div>
				</td>
				<td class="-w50" rowspan="1" colspan="1">
					<div class="-cell">
						<label class="-w25">ชื่อบน Invoice</label>
						<p>{{ submission.invoice_to.name }}</p>
					</div>
				</td>
			</tr>
			<tr>
				<td rowspan="3" colspan="1">
					<div class="-cell">
						<label class="-w25">ที่อยู่</label>
						<p class="-pre">{{ submission.sample_owner.address }}</p>
					</div>
				</td>
				<td rowspan="3" colspan="1">
					<div class="-cell">
						<label class="-w25">ที่อยู่</label>
						<p class="-pre">{{ submission.invoice_to.address }}</p>
					</div>
				</td>
			</tr>
		</table>

		<table v-if="submission.is_general" class="-space-above">
			<tr>
				<td colspan="2" rowspan="1" class="-w33">
					<div class="-cell">
						<label class="-w50">ประเภทตัวอย่าง</label>
						<p>{{ submission.sample_details.sample_type }}</p>
					</div>
				</td>
				<td colspan="2" rowspan="1" class="-w33">
					<div class="-cell">
						<label class="-w50">ชนิดสัตว์</label>
						<p>{{ submission.sample_details.animal_type }}</p>
					</div>
				</td>
				<td colspan="2" rowspan="1" class="-w33">
					<div class="-cell">
						<label class="-w50">วันที่เก็บตัวอย่าง</label>
						<p>{{ submission.sample_details.sample_taken_date? to_display_date(submission.sample_details.sample_taken_date) : ''}}</p>
						</div>
						</td>
			</tr>
			<tr>
				<td colspan="2" rowspan="1" class="-w33">
					<div class="-cell">
						<label class="-w50">พันธุ์</label>
						<p>{{ submission.sample_details.animal_species }}</p>
					</div>
				</td>
				<td colspan="2" rowspan="1" class="-w33">
					<div class="-cell">
						<label class="-w50">อายุสัตว์</label>
						<p>{{ submission.sample_details.animal_age }}</p>
					</div>
				</td>
				<td colspan="2" rowspan="1" class="-w33">
					<div class="-cell">
						<label class="-w50">จำนวนที่เลี้ยง</label>
						<p>{{  submission.sample_details.animal_count  }}</p>
					</div>
				</td>
			</tr>
			<tr>
				<td colspan="3" rowspan="1" class="-w50">
					<div class="-cell">
						<label class="-w33">ประวัติการป่วย</label>
						<p class="-pre">{{ submission.sample_details.illness }}</p>
					</div>
				</td>
				<td colspan="3" rowspan="1" class="-w50">
					<div class="-cell">
						<label class="-w33">ประวัติการทำวัคซีน</label>
						<p class="-pre">{{ submission.sample_details.vaccinations }}</p>
					</div>
				</td>
			</tr>
		</table>

		<template v-if="submission.is_general">
			<div  v-for="(batch, idx) of submission.submission_batches"
						:key="idx">

				<div class="-font-lg -space-above">
					{{ submission.submission_batches.length > 1? `กลุ่มการทดสอบ ${idx+1}` : 'การทดสอบ' }}
				</div>
				<div class="-font-md -margin-b">
					<span class="-ink -margin-r">{{ batch.sample_count }}</span>
					<span>ตัวอย่าง</span>
				</div>
				<div  v-for="(department, department_name) in batch.tests"
							:key="department_name">
					<table v-if="department" class="-space-below-sm -test-table">
						<tr>
							<td colspan="10" class="-w100 -department">
								<div class="-cell">
									<label class="-font-md">งาน{{ department_name }}</label>
								</div>
							</td>
						</tr>
						<tr>
							<td colspan="2" class="-w20">
								<div class="-cell">
									<label class="-muted">กลุ่ม</label>
								</div>
							</td>
							<td colspan="6" class="-w60">
								<div class="-cell">
									<label class="-muted">รายการทดสอบ</label>
								</div>
							</td>
							<td colspan="1" class="-w10">
								<div class="-cell -flex-end">
									<label class="-muted -font-shrink">ราคา/ตัวอย่าง</label>
								</div>
							</td>
							<td colspan="1" class="-w10">
								<div class="-cell -flex-end">
									<label class="-muted">ค่าบริการ</label>
								</div>
							</td>
						</tr>
						<div  v-for="(category, category_name) in department.test_list"
									:key="category_name">
							<tr v-for="(test, idx_test) of category"
									:key="idx_test">
								<td v-if="idx_test === 0"
										:rowspan="category.length"
										colspan="2"
										class="-w20">
									<div class="-cell">
										<label>{{ category_name }}</label>
									</div>
								</td>
								<td colspan="6" class="-w60">
									<div class="-cell">
										<p>{{ test.display_name }}</p>
									</div>
								</td>
								<td colspan="1" class="-w10">
									<div class="-cell -flex-end">
										<label>{{ to_display_price(test.price) }}</label>
									</div>
								</td>
								<td colspan="1" class="-w10">
									<div class="-cell -flex-end">
										<p>{{ to_display_price(test.price * batch.sample_count) }}</p>
									</div>
								</td>
							</tr>
						</div>
						<template v-if="department.custom_bacteria_tests">
							<tr v-for="(bacteria_test, idx_bacteria_test) of department.custom_bacteria_tests"
									:key="idx_bacteria_test">
								<td v-if="idx_bacteria_test === 0"
										:rowspan="department.custom_bacteria_tests.length"
										colspan="2"
										class="-w20">
									<div class="-cell">
										<label>Bacterial Identification (ใส่ชื่อแบคทีเรียเอง)</label>
									</div>
								</td>
								<td colspan="6" class="-w60">
									<div class="-cell">
										<p>{{ bacteria_test }}</p>
									</div>
								</td>
								<td colspan="1" class="-w10">
									<div class="-cell -flex-end">
										<label>~{{ to_display_price(500) }}</label>
									</div>
								</td>
								<td colspan="1" class="-w10">
									<div class="-cell -flex-end">
										<p>~{{ to_display_price(500 * batch.sample_count) }}</p>
									</div>
								</td>
							</tr>
							<tr v-if="department.sensitivity_tests">
								<td colspan="2"
										class="-w20">
									<div class="-cell">
										<label>ยาต้านจุลชีพเพื่อการทดสอบความไว</label>
									</div>
								</td>
								<td colspan="6"
										class="-w60">
									<div class="-cell">
										<p>{{ department.sensitivity_tests.split(',').join(', ') }}</p>
									</div>
								</td>
								<td colspan="2" class="-w20"></td>
							</tr>
						</template>
					</table>
				</div>

				<table class="-space-below-sm -space-above-sm">
					<tr>
						<td class="-w35">
							<div class="-cell -font-md">
								<label class="-margin-r">จำนวน</label>
								<p>{{ batch.sample_count }} ตัวอย่าง</p>
							</div>
						</td>
						<td>
							<div class="-cell -font-md">
								<label class="-margin-r">รายการทดสอบ</label>
								<p>{{ batch.test_count }} รายการ</p>
							</div>
						</td>
						<td class="-w20">
							<div class="-cell -font-md -flex-between">
								<label>ค่าบริการ</label>
								<p>{{ to_display_price(batch.price) }}</p>
							</div>
						</td>
					</tr>
				</table>

				<div class="-nobreak">
					<div class="-font-md">รายละเอียดตัวอย่าง</div>
					<table v-if="batch.samples.length <= 3">
						<tr>
							<td colspan="1" class="-w10">
								<div class="-cell">
									<label class="-muted">หมายเลข</label>
								</div>
							</td>
							<td colspan="3" class="-w25">
								<div class="-cell">
									<label class="-muted">ID ตัวอย่าง</label>
								</div>
							</td>
							<td colspan="6">
								<div class="-cell">
									<label class="-muted">ข้อมูลเพิ่มเติม</label>
								</div>
							</td>
						</tr>
						<tr v-for="(sample, idx_sample) of batch.samples"
								:key="idx_sample">
							<td colspan="1" class="-w10">
								<div class="-cell">
									<label>{{ idx_sample + 1 }}</label>
								</div>
							</td>
							<td colspan="3" class="-w25">
								<div class="-cell">
									<p>{{ sample.sample_id }}</p>
								</div>
							</td>
							<td colspan="6">
								<div class="-cell">
									<p>{{ sample.extra_info }}</p>
								</div>
							</td>
						</tr>
					</table>
					<div v-else class="-grid-2col">
						<table v-for="slice of [{ start: 0, end: Math.ceil(batch.samples.length / 2) }, { start: Math.ceil(batch.samples.length / 2), end: batch.samples.length }]"
								:key="slice.start">
							<tr>
								<td colspan="1" class="-w10">
									<div class="-cell">
										<label class="-muted">หมายเลข</label>
									</div>
								</td>
								<td colspan="3" class="-w25">
									<div class="-cell">
										<label class="-muted">ID ตัวอย่าง</label>
									</div>
								</td>
								<td colspan="6">
									<div class="-cell">
										<label class="-muted">ข้อมูลเพิ่มเติม</label>
									</div>
								</td>
							</tr>
							<tr v-for="(sample, idx_sample) of batch.samples.slice(slice.start, slice.end)"
									:key="idx_sample">
								<td colspan="1" class="-w10">
									<div class="-cell">
										<label>{{ idx_sample + slice.start + 1 }}</label>
									</div>
								</td>
								<td colspan="3" class="-w25">
									<div class="-cell">
										<p>{{ sample.sample_id }}</p>
									</div>
								</td>
								<td colspan="6">
									<div class="-cell">
										<p>{{ sample.extra_info }}</p>
									</div>
								</td>
							</tr>
						</table>
					</div>
				</div>
			</div>

		</template>

		<template v-else-if="submission.is_disinfectant">
			<div  v-for="(batch, idx) of submission.submission_batches"
						:key="idx"
						class="-space-below">
				<div class="-font-lg -space-above">
					<span class="-margin-r">ยาฆ่าเชื้อ</span>
					<span class="-ink">{{ batch.disinfectant_name }}</span>
				</div>
				<div class="-font-md -margin-b">
					<span class="-margin-r">ทดสอบประสิทธิภาพต่อ</span>
					<span class="-ink">{{ batch.test_type }}</span>
				</div>
				<div class="-table-wrapper -space-below-sm -test-table">
					<table v-for="(test, test_name, idx_entry) in batch.tests"
									:key="idx_entry">

						<template v-if="!test.dilution_times">
							<tr v-if="idx_entry === 0">
								<th rowspan="1" colspan="3" class="-w30">
									<div class="-cell">
										<label>ชื่อ {{ batch.is_virus? 'ไวรัส' : 'แบคทีเรีย' }}</label>
									</div>
								</th>
								<th rowspan="1" colspan="3" class="-w30">
									<div class="-cell">
										<label>ความเข้มข้น</label>
									</div>
								</th>
								<th rowspan="1" colspan="3" class="-w25">
									<div class="-cell">
										<label>ระยะสัมผัสเชื้อ</label>
									</div>
								</th>
								<th rowspan="1" colspan="1" class="-w15">
									<div class="-cell -flex-end">
										<label>ค่าบริการ</label>
									</div>
								</th>
							</tr>

							<div v-for="(dilution, idx_dilution) of test.dilutions"
								:key="idx_dilution">
								<tr v-for="(contact_time, idx_contact_time) of test.contact_times"
										:key="idx_contact_time">
									<td v-if="idx_dilution === 0 && idx_contact_time === 0"
											:rowspan="test.test_count"
											colspan="3"
											class="-w30">
										<div class="-cell -font-md">
											<p>{{ batch.is_virus? test.display_name : test_name }}</p>
										</div>
									</td>
									<td v-if="idx_contact_time === 0"
											:rowspan="test.contact_times.length"
											colspan="3"
											class="-w30">
										<div class="-cell -font-md">
											<p>{{ dilution }}</p>
										</div>
									</td>
									<td rowspan="1" colspan="3"
											class="-w25">
										<div class="-cell">
											<p>{{ contact_time }}</p>
										</div>
									</td>
									<td rowspan="1" colspan="1"
											class="-w15">
										<div class="-cell -flex-end">
											<p>{{ to_display_price(3000) }}</p>
										</div>
									</td>
								</tr>
							</div>
						</template>

						<template v-else>
							<tr v-if="idx_entry === 0">
								<th rowspan="1" colspan="3" class="-w25">
									<div class="-cell">
										<label>ชื่อ {{ batch.is_virus? 'ไวรัส' : 'แบคทีเรีย' }}</label>
									</div>
								</th>
								<th rowspan="1" colspan="2" class="-w20">
									<div class="-cell">
										<label>ความเข้มข้น</label>
									</div>
								</th>
								<th rowspan="1" colspan="2" class="-w20">
									<div class="-cell">
										<label>ระยะหลังการเจือจาง</label>
									</div>
								</th>
								<th rowspan="1" colspan="2" class="-w20">
									<div class="-cell">
										<label>ระยะสัมผัสเชื้อ</label>
									</div>
								</th>
								<th rowspan="1" colspan="1" class="-w15">
									<div class="-cell -flex-end">
										<label>ค่าบริการ</label>
									</div>
								</th>
							</tr>

							<div v-for="(dilution, idx_dilution) of test.dilutions"
								:key="idx_dilution">
								<div  v-for="(dilution_time, idx_dilution_time) of test.dilution_times"
											:key="idx_dilution_time">
									<tr v-for="(contact_time, idx_contact_time) of test.contact_times"
											:key="idx_contact_time">
										<td v-if="idx_dilution === 0 && idx_dilution_time === 0 && idx_contact_time === 0"
												:rowspan="test.test_count"
												colspan="3"
												class="-w25">
											<div class="-cell -font-md">
												<p>{{ batch.is_virus? test.display_name : test_name }}</p>
											</div>
										</td>
										<td v-if="idx_dilution_time === 0 && idx_contact_time === 0"
												:rowspan="test.dilution_times.length * test.contact_times.length"
												colspan="2"
												class="-w20">
											<div class="-cell -font-md">
												<p>{{ dilution }}</p>
											</div>
										</td>
										<td v-if="idx_contact_time === 0"
												:rowspan="test.contact_times.length"
												colspan="2"
												class="-w20">
											<div class="-cell">
												<p>{{ dilution_time }}</p>
											</div>
										</td>
										<td rowspan="1" colspan="2"
												class="-w20">
											<div class="-cell">
												<p>{{ contact_time }}</p>
											</div>
										</td>
										<td rowspan="1" colspan="1"
												class="-w15">
											<div class="-cell -flex-end">
												<p>{{ to_display_price(3000) }}</p>
											</div>
										</td>
									</tr>
								</div>
							</div>
						</template>

					</table>
					<table>
						<tr v-if="batch.is_virus">
							<td>
								<div class="-cell -flex-end">
									<label>ค่า Toxicity Test</label>
								</div>
							</td>
							<td colspan="1" class="-w15">
								<div class="-cell -flex-end">
									<p>{{ to_display_price(batch.toxicity_test_cost) }}</p>
								</div>
							</td>
						</tr>
						<tr>
							<td>
								<div class="-cell -flex-end">
									<label>ค่าประเมินผล</label>
								</div>
							</td>
							<td colspan="1" class="-w15">
								<div class="-cell -flex-end">
									<p>{{ to_display_price(3000) }}</p>
								</div>
							</td>
						</tr>
						<tr>
							<td>
								<div class="-cell -flex-end">
									<label>ยอดค่าบริการ</label>
								</div>
							</td>
							<td colspan="1" class="-w15">
								<div class="-cell -flex-end">
									<p>{{ to_display_price(batch.price) }}</p>
								</div>
							</td>
						</tr>
					</table>
				</div>
			</div>
		</template>

		<template v-if="submission.submission_batches && submission.submission_batches.length > 1">
			<div class="-font-lg -space-above">ข้อมูลสรุป</div>
			<table class="-space-below-sm">
				<tr>
					<td v-if="submission.is_general" class="-w35">
						<div class="-cell -font-md">
							<label class="-margin-r">จำนวน</label>
							<p>{{ submission.submission_batches.reduce((samples, batch) => samples += batch.sample_count, 0) }} ตัวอย่าง</p>
						</div>
					</td>
					<td>
						<div class="-cell -font-md">
							<label class="-margin-r">รายการทดสอบ</label>
							<p>{{ submission.submission_batches.reduce((tests, batch) => tests += batch.test_count, 0) }} รายการ</p>
						</div>
					</td>
					<td class="-w35">
						<div class="-cell -font-md -flex-between">
							<label>ค่าบริการทั้งหมด</label>
							<p>{{ to_display_price(submission.submission_batches.reduce((price, batch) => price += batch.price, 0)) }}</p>
						</div>
					</td>
				</tr>
			</table>
		</template>

		<table v-if="submission.remarks" class="-space-above">
			<tr>
				<td class="-w100">
					<div class="-cell">
						<label class="-w15">หมายเหตุอื่นๆ</label>
						<p class="-pre -height-3-lines">{{ submission.remarks }}</p>
					</div>
				</td>
			</tr>
		</table>

	</div>
</div>
</template>

<script>
import { BASE_URL } from '@/api'

export default {
	name: 'submission-form',
	props: {
		show_form: {
			type: Boolean,
			default: false,
		},
		submission: {
			type: Object,
		},
		iframe_key: {
			type: String,
			required: true,
		}
	},
	computed: {
		iframe_src () {
			return `${BASE_URL}/submission-form/submission-form.html`
		},
		print_element_id () {
			return `${this.iframe_key}-print-area`
		}
	},
	mounted () {
		const iframe = document.getElementById(this.iframe_key)
		iframe.contentDocument.write(this.gen_submission_form_html())
		const iframe_body = iframe.contentDocument.getElementById('iframe-body')
		if (iframe_body) {
			const submission_form_html = document.getElementById(this.print_element_id).innerHTML
			iframe_body.innerHTML = submission_form_html
			iframe.height = iframe_body.scrollHeight + 30
		}
	},
	methods: {
		gen_submission_form_html () {
			return `
				<html>
					<head>
						<title>Submission Form</title>
						<link rel="stylesheet" type="text/css" href="${BASE_URL}/submission-form/submission-form.css" />
					</head>
					<body id="iframe-body"><body>
				</html>  
			` 
		}
	}
}
</script>

<style lang="scss">
.iframe-wrapper {
	width: 893px;
	max-width: 893px;
	padding-top: 7rem;
	margin-bottom: 10rem;
	&.hide {
		visibility: hidden;
		position: fixed;
		top: 0;
		left: 0;
		height: 0;
		width: 0px
	}
}

iframe {
	width: 100%;
	max-width: 893px;
	border: none;
}
</style>