<template>
<div class="form-group">
  <button class="btn btn-block font-cu"
          :class="btnClass"
          @click="download_submission_form()">
    <i  v-if="iconClass"
        class="mr-2"
        :class="iconClass" />
    {{ label }}
  </button>

  <SubmissionForm
    v-if="submission"
    :iframe_key="iframe_key"
    :submission="submission" />

</div>
</template>

<script>

export default {
  name: 'download-submission-form',
  props: {
    label: {
      type: String,
      default: 'Download ใบส่งตัวอย่าง'
    },
    btnClass: {
      type: String,
      default: 'btn-secondary'
    },
    iconClass: {
      type: String,
      default: 'fas fa-file-alt btn-inner-icon'
    },
    submission: {
      type: Object,
    },
    iframe_key: {
      type: String,
      required: true,
    }
  },
  methods: {
    download_submission_form () {
      const saved_document_title = document.title
      document.title = this.submission.BestLIMS_key
      document.getElementById(this.iframe_key).contentWindow.print()
      document.title = saved_document_title
    },
  }
}
</script>